import { isHtmlElement } from './type-guards/isHtmlElement';

/*
Usage:
    To apply a lazyloaded background image to an element (where 'background-image' in CSS is no longer needed),
    add 'data-background-image' followed by the CSS value that would have been used for 'background-image'.
    also add 'lazyload-background' class.
    BackgroundImage only accepts the 'image' part, options such as 'background-repeat' need to added to the CSS manually.
Example:
    <div data-background-image="url(imageUrl)" class="lazyload-background"></div>
*/

const lazyLoad = () => {
    const lazyloadElements: NodeListOf<HTMLElement> = document.querySelectorAll('.lazyload-background');

    if ('IntersectionObserver' in window) {
        const observerOptions = { rootMargin: '200px' };

        const handleIntersection = (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
            entries.forEach((entry) => {
                const targetElement = entry.target;

                if (entry.isIntersecting && isHtmlElement(targetElement)) {
                    targetElement.style.backgroundImage = targetElement.dataset.backgroundImage;
                    observer.unobserve(entry.target);
                }
            });
        };

        const observer = new IntersectionObserver(handleIntersection, observerOptions);
        lazyloadElements.forEach((element) => observer.observe(element));
    } else {
        lazyloadElements.forEach((element) => {
            element.style.backgroundImage = element.dataset.backgroundImage;
        });
    }
};

document.addEventListener('DOMContentLoaded', lazyLoad);
